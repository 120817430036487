








































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { deleteLocationAlert, revokeLocationAlert, suspendLocationAlert, 
  unblockAllRepsAlert, blockAllRepsAlert } from "@/utils/notificationContent";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import LocationForm from "@/components/locations/LocationForm.vue";
import CancelDeleteAppointmentModal from "@/components/cancelDeleteAppointmentModal/CancelDeleteAppointmentModal.vue";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    AppModal,
    LocationForm,
    CancelDeleteAppointmentModal
  },
})
export default class LocationList extends Vue {
  @Action public initNewLocation: any;
  @Action public getLocations: any;
  @Action public updateLocation: any;
  @Action public getLocationCount: any;
  @Action public setLocation: any;
  @Getter public locationInitData: any;
  @Getter public tenantLocations: any;
  @Getter public locationCount: any;
  @Getter public role!: any;
  @Action public suspendOrRevokeLocation: any;
  @Action public getAppointmentTypesByLocation: any;
  @Getter public rolePerm: any;
  @Action public updatePracticeLocation: any;
  perm = _permissions;
  locationFilter: any = {
    limit:20,
    skip:0,
    where: { status: { eq:true } }, 
    include: [
      {
        relation: "contact_primary", 
        scope: { 
          fields: [
            "id",
            "first_name",
            "last_name",
            "phone"
          ],
          where:{
            userTypesId:2,
            status:1
          }
        }
      },
    ],
    order: ["name ASC"]
  };
  ref: any = "appModalRef";
  pageOptions = [10, 20];
  currentPage = 1;
  searchText = "";
  $refs!: {
    appModalRef: HTMLFormElement;
    cancelDeleteMeetingModal: HTMLFormElement;
  }
  selectedLocation: any = {};
  isDeleteAppointments = false;

  beforeMount() {
    this.getLocationList();
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      this.locationFilter.where.or = [
        { name: { ilike: `%${this.searchText}%` } },
      ];
      this.locationFilter.skip = 0;
      this.getLocationList();
    }
  }

  clearSearch() {
    this.searchText = '';
    this.onChangeFilterInput();
  }

  getLocationList() {
    this.getLocations(this.locationFilter);
    this.getLocationCount(this.locationFilter.where);
  }

  addLocation() {
    this.setLocation({});
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  actionPerformed() {
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.hide();
    this.getLocationList();
  }

  openCancelAppointmentModal(location: any) {
    this.isDeleteAppointments = false;
    this.getAppointmentTypesByLocation({id: location.id, filter: {"fields":["id","appointment_type"]}});
    this.selectedLocation = location;
    const cancelDeleteMeetingModal = this.$refs.cancelDeleteMeetingModal as HTMLFormElement;
    cancelDeleteMeetingModal.show();
  }


  openCancelAndDeleteAppointmentModal(location: any) {
    this.isDeleteAppointments = true;
    this.getAppointmentTypesByLocation({id: location.id, filter: {"fields":["id","appointment_type"]}});
    this.selectedLocation = location;
    const cancelDeleteMeetingModal = this.$refs.cancelDeleteMeetingModal as HTMLFormElement;
    cancelDeleteMeetingModal.show();
  }

  editLocation(id: number): void {
    this.$router.push({ path: `/locations/update/${id}` });
  }

  deleteLocation(id: number): void {
    alert.methods.conFirmAlert(
      deleteLocationAlert,
      () => {
        this.updateLocation({
          data: { id: id, status: false },
          callback: this.getLocationList,
        });
        setTimeout(() => {
          this.getLocationList();
        }, 1000);
      },
      () => {}
    );
  }

  pageSizeChanged() {
    this.locationFilter.skip = 0;
    this.getLocationList();
  }

  onPageChange(currentPage: any) {
    this.locationFilter.skip = (currentPage - 1) * this.locationFilter.limit;
    this.getLocationList();
  }

  getAddressDetails(location: any) {
    let addressStr = location.address;
    if (location.city) {
      addressStr = `${addressStr}, ${location.city}`;
    }
    if (location.state) {
      addressStr = `${addressStr}, ${location.state}`;
    }
    if (location.zipcode) {
      addressStr = `${addressStr}, ${location.zipcode}`;
    }
    return addressStr;
  }

  suspendAppointmentsInLocation(location: any) {
  alert.methods.conFirmAlert(
      suspendLocationAlert,
      () => {
        this.suspendOrRevokeLocation({
          data: { id: location.id, suspend_appointments: true },
          callBack: () => {
            this.getLocationList();
          },
        });
      },
      () => {}
    );
  }

  revokeLocationAppointments(location: any) {
    alert.methods.conFirmAlert(
      revokeLocationAlert,
      () => {
        this.suspendOrRevokeLocation({
          data: { id: location.id, suspend_appointments: false },
          callBack: () => {
            this.getLocationList();
          },
        });
      },
      () => {}
    );
  }

  onBlockOrUnblockReps(dataObj:any, block:boolean){
    const data = {
      locationId: dataObj.id,
      status: false,
      tenant_id: dataObj.tenantId,
      block_reps:block
    };
    alert.methods.conFirmAlert(
      block ? blockAllRepsAlert : unblockAllRepsAlert,
      () => {
        this.updatePracticeLocation({data,callback: ()=>{this.getLocationList()}});
      },
      () => {}
    );    
  }
}
