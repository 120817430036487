

















































































































































































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
import moment from "moment";
import DatePicker from "../datepicker/index.vue";

@Component({
  components: {
    DatePicker,
  },
})
export default class GenerateMeetingForm extends Vue {
  @Prop({ default: "md" }) public size!: any;
  @Prop() public selectedLocation: any;
  @Prop() public selectedAppointmentType: any;
  @Prop() public endDateDisabled: any;

  @Getter public currentUser: any;
  @Action public generateMeetings: any;

  isSubmitted = false;
  $refs!: {
    generateMeetingForm: InstanceType<typeof ValidationObserver>;
  };
  generateMeetingDate = {
    startDate: null,
    endDate: null,
  };
  minStartDate = "";
  minEndDate = "";
  maxEndDate = "";
  timezone = "";
  selected: any = [];
  withoutWeekEndsOptions = [1, 2, 3, 4, 5];
  withWeekEndsOptions = [0, 1, 2, 3, 4, 5, 6];
  showCheckBox = false;
  // disableSelectAllCheckBox = false;
  defaultScheduledValue = 1;
  scheduleDateOptions = [
    { text: 'Now', value: 1 },
    { text: 'Date & Time', value: 2 }
  ];

  recurringTypes = [
    { text: 'Weekly', value: 'weekly' },
    { text: 'Monthly', value: 'monthly' }
  ];
  enabledTime = true;
  scheduleDate = false;
  scheduleTime = false;

  dayOfWeekList = [
    { text: 'Mon', value: 1 },
    { text: 'Tue', value: 2 },
    { text: 'Wed', value: 3 },
    { text: 'Thu', value: 4 },
    { text: 'Fri', value: 5 },
  ];

  weekOfMonthList = [
    { text: 'first', value: 1 },
    { text: 'second', value: 2 },
    { text: 'third', value: 3 },
    { text: 'forth', value: 4 },
    { text: 'last', value: 5 },
  ];
  
  customSchedule:any = {
    recurringType : 'weekly',
    separationCount: 1,
    dayOfWeek : [this.dayOfWeekList[0]],
  weekOfMonth : 1,
  monthlyOn : 1,
  dayOfMonth : 1,
  disableAppointmentNotification: false
  }


  beforeMount() {
    this.showCheckBox = true;
    this.minStartDate = moment().toISOString();
    this.customSchedule.dayOfMonth = moment().date();
    if(this.currentUser.tenant.allow_weekend_meetings){
      this.dayOfWeekList = [...this.dayOfWeekList,{ text: 'Sun', value: 0 },{ text: 'Sat', value: 6 },]
    }
    // const todayDay = this.dayOfWeekList.find(v=>v.value === moment().days());
    // if(todayDay){
    //   this.customSchedule.dayOfWeek = [todayDay];
    // }    
  }

  @Watch("selectedAppointmentType")
  onAppointmentTypeChanged() {
    // this.disableSelectAllCheckBox = this.selectedAppointmentType.selected_days && this.selectedAppointmentType.selected_days.length !== 0 ? true : false;
    this.showCheckBox = true;
    this.selected = this.selectedAppointmentType.selected_days ? this.selectedAppointmentType.selected_days : [];
    this.generateMeetingDate = {
      startDate: null,
      endDate: null,
    };
    if (this.selectedAppointmentType.meeting_card_end_date && this.selectedAppointmentType.meeting_card_end_date !== "") {
      const meetingCardEndDate = moment(this.selectedAppointmentType.meeting_card_end_date).format('YYYY-MM-DD');
      if (moment().isBefore(moment(meetingCardEndDate))) {
        this.minStartDate = moment(`${moment(meetingCardEndDate).add(1, 'days').format('YYYY-MM-DD')}T00:00:00.000Z`).toISOString();
      } else {
        this.minStartDate = moment().toISOString();
      }
    } else {
      this.minStartDate = moment().toISOString();
    }
    if (moment(this.selectedAppointmentType.start_time, 'hh:mm A').isBefore(moment())) {
      this.minStartDate = moment(this.minStartDate).add(1, 'days').toISOString();
    }
  }

  setStartDate(value: any) {
    this.generateMeetingDate.startDate = value;
    this.minEndDate = moment(moment(value).toISOString())
      .add(3, "months")
      .toISOString();
    this.maxEndDate = moment(moment(value).toISOString())
      .add(2, "years")
      .toISOString();
    this.endDateDisabled = false;
    this.customSchedule.dayOfMonth = moment(value).date();
    //this.customSchedule.dayOfWeek = [this.dayOfWeekList.find(v=>v.value === moment(value).days())];
  }

  setEndDate(value: any) {
    this.generateMeetingDate.endDate = value;
  }

  // isDisabled(value: number) {
  //   return this.selectedAppointmentType.selected_days ? this.selectedAppointmentType.selected_days.indexOf(value) === -1 : false;
  // }

  toggleAll(checked: any) {
    if (checked) {
      this.selected = this.currentUser.tenant.allow_weekend_meetings ? this.withWeekEndsOptions : this.withoutWeekEndsOptions;
    } else {
      this.selected = [];
    }
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    if (
      this.generateMeetingDate.startDate !== null &&
      this.generateMeetingDate.endDate !== null
    ) {
      this.$refs.generateMeetingForm.validate().then((success: any) => {
        if (!success) {
          alert.methods.appToaster({
            message: constants.FORM_ERROR,
            type: "ERROR",
          });
          const firstErrorFocused: HTMLFormElement = Object(
            document.querySelector(".error:first-of-type")
          );
          if (
            isNotEmpty(firstErrorFocused) &&
            !firstErrorFocused.classList.contains("IZ-select")
          ) {
            firstErrorFocused.querySelector("input");
            firstErrorFocused.focus();
          }
          const errors = Object.entries(this.$refs.generateMeetingForm.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          if (errors.length) {
            this.$refs.generateMeetingForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
          return;
        } else {
          if (this.customSchedule.recurringType === 'weekly' && this.selected && this.selected.length === 0) {
            alert.methods.appToaster({ message: 'Select atleast one day to generate appointments', type: "ERROR" });
            return;
          }

          if(+this.customSchedule.monthlyOn === 2 && (!this.customSchedule.weekOfMonth || this.customSchedule.dayOfWeek.length===0)){
            alert.methods.appToaster({ message: 'Select week of month and days to generate appointments', type: "ERROR" });
            return;
          }

          if (this.defaultScheduledValue === 2 && (!this.scheduleDate || !this.scheduleTime)) {
            alert.methods.appToaster({ message: 'Select scheduled open date & time', type: "ERROR" });
            return;
          }
          const weekdays = this.selected.map((str: any) => {
            return Number(str);
          });       

          const meetingData:any = {
            id: this.selectedAppointmentType.id,
            locationId: this.selectedLocation.id,
            appointmentTypeId: this.selectedAppointmentType.appointmentType.id,
            startDate: this.generateMeetingDate.startDate,
            endDate: this.generateMeetingDate.endDate,
            startTime: moment(this.selectedAppointmentType.start_time, 'hh:mm A'),
            endTime: moment(this.selectedAppointmentType.end_time, 'hh:mm A'),
            appointment_label: this.selectedAppointmentType.appointment_label,
            selected_days: weekdays,
            scheduled_open_at: this.selectedAppointmentType.scheduled_open_at || moment().toISOString(),
            separation_count: +this.customSchedule.separationCount,
            recurring_type: this.customSchedule.recurringType,
            disableAppointmentNotification: this.customSchedule.disableAppointmentNotification
          };

          if(this.customSchedule.recurringType === 'weekly'){
            if(+this.customSchedule.separationCount === 1){
              meetingData.recurring_type = 'daily';
            }
            const sDate = moment(this.generateMeetingDate.startDate);
            const eDate = moment(this.generateMeetingDate.endDate);
            const no_of_weeks = eDate.diff(sDate,'weeks');
            if(no_of_weeks < +this.customSchedule.separationCount && !weekdays.includes(sDate.day())){
               return alert.methods.appToaster({ message: 'The recurrence patteren is not valid', type: "ERROR" });
            }
          }

          if(this.customSchedule.recurringType === 'monthly'){ 
            if(+this.customSchedule.monthlyOn === 1){
              meetingData.selected_days = null;
              meetingData.day_of_month = this.customSchedule.dayOfMonth;
              meetingData.recurring_type = 'monthlyOnSpecificDay';
            } else {
              meetingData.selected_days = this.customSchedule.dayOfWeek?.map((v:any)=>v.value) ?? [];
              meetingData.week_of_month = this.customSchedule.weekOfMonth;
              meetingData.monthlyOn = 2;
            }     
          }
          this.generateMeetings({ data: meetingData, callback: this.onSubmit });
        }
      });
    } else {
      alert.methods.appToaster({
        message: constants.GENERATE_MEETING_CARD_FORM_ERROR,
        type: "ERROR",
      });
      return;
    }
  }

  onCancel() {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.hide();
      },
      () => {
      }
    );
  }

  show(): void {
    this.$bvModal.show("generate-meeting-modal");
  }

  @Emit("resetAppointmentType")
  hide() {
    this.endDateDisabled = true;
    this.selected = [];
    this.customSchedule = {
    recurringType : 'weekly',
    separationCount: 1,
    dayOfWeek : [this.dayOfWeekList[0]],
    weekOfMonth : 1,
    monthlyOn : 1,
    dayOfMonth : moment().date(),
    disableAppointmentNotification: false
  }
  this.showCheckBox = true;
  this.defaultScheduledValue = 1;
    this.$bvModal.hide("generate-meeting-modal");
    return {};
  }

  @Emit("getLocationDetails")
  onSubmit() {
    this.hide();
    this.selected = [];
    return true;
  }

  selectScheduledDate(e: any) {
    this.selectedAppointmentType.scheduled_open_at = undefined;
    this.defaultScheduledValue = e;
    if (e === 1) {
      this.scheduleDate = false;
      this.scheduleTime = false;
      this.enabledTime = true;
    }
  }

  setDate(value: any) {
    const time = this.selectedAppointmentType.scheduled_open_at;
    this.selectedAppointmentType.scheduled_open_at = value;
    this.scheduleDate = true;
    if (time) {
      this.setTime(time);
    }
    this.enabledTime = false;
  }

  setTime(time: any): void {
    if (time) {
      const sTime = moment(time);
      const sDate = moment(this.selectedAppointmentType.scheduled_open_at)
      sTime.date(sDate.date());
      sTime.month(sDate.month());
      sTime.year(sDate.year());
      this.selectedAppointmentType.scheduled_open_at = sTime;
      this.scheduleTime = true;
    }
  }

  // selectRecurringType(e: any) {
  //   this.recurringType = e;
  // }

  // getCustomScheduleDetails():string{
  //   const schedulePattern = this.recurringType === 1 ? 'Weekly' : 'Monthly';
  //   return `${this.separationCount=== 1 ? schedulePattern : 'Every ' +this.separationCount+ ' ' }`
  // }

  // setWeekOfMonth(e:any){
  //   this.weekOfMonth = e;
  // }

  // setDayOfMonth(e:any){
  //   this.dayOfWeek = e;
  // }

  getWeekdayOccurrence(date) {
    const day = date.getDate();
    const weekday = date.getDay();
    let occurrence = 0;
    
    for (let d = 1; d <= day; d++) {
        const currentDay = new Date(date.getFullYear(), date.getMonth(), d).getDay();
        if (currentDay === weekday) {
            occurrence++;
        }
    }
    return occurrence;
}

}
