import Login from "@/views/login/Login.vue";
import Preference from "@/views/preference/Preference.vue";
import ResetPassword from "@/views/resetpassword/ResetPassword.vue";
import EmptyRouterView from "@/components/emptyRouterView/Index.vue";
import permission from "@/constants/_permissions";
import modules from "@/constants/_modules";
import Register from "@/views/registerForm/RegisterForm.vue";
import RegisterSuccess from "@/views/registerForm/RegisterSuccess.vue";
import RegisterApproval from "@/views/registerForm/RegisterApproval.vue";
import Subscription from "@/views/subscription/Subscription.vue";
import ForgotPassword from "@/views/forgotPassword/ForgotPassword.vue";
import Messages from "@/views/message/Messages.vue";
import LocationList from "@/views/location/LocationList.vue";
import LocationUpdateForm from "@/views/location/LocationUpdateForm.vue";
import RestaurantList from "@/views/restaurant/RestaurantList.vue";
import RestaurantForm from "@/views/restaurant/RestaurantForm.vue";
import TeamList from "@/views/teams/TeamList.vue";
import PaymentForm from '@/views/payment/PaymentForm.vue';
import PracticeList from '@/views/practice/PracticeList.vue';
import PhysicianRoomList from '@/views/physicianRoom/PhysicianRoomList.vue';
import PhysicianRoomForm from '@/views/physicianRoom/PhysicianRoomForm.vue';
import SubscriberList from '@/views/subscribers/SubscriberList.vue';
import SubscriberForm from '@/views/subscribers/SubscriberForm.vue';
import PhysicianList from '@/views/physicians/PhysicianList.vue';
import PhysicianForm from '@/views/physicians/PhysicianForm.vue';
import Dashboard from "@/views/dashboard/Dashboard.vue";
import MeetingApprovalList from "@/views/meetingApproval/MeetingApprovalList.vue";

const routes = [
  {
    path: "/login",
    name: "login.index",
    component: Login,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: "/register",
    name: "register.index",
    component: Register,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: "/registerSuccess",
    name: "registerSuccess.index",
    component: RegisterSuccess,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: "/registerApproval",
    name: "registerApproval.index",
    component: RegisterApproval,
    meta: {
      requiresAuth: true,
      module: modules.USER,
      permission: permission.CREATE,
    }
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword.index",
    component: ForgotPassword,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/profile",
    name: "preference.index",
    component: Preference,
    meta: {
      requiresAuth: true,
      module: modules.PROFILE,
      permission: permission.UPDATE
    }
  },
  {
    path: "/resetpassword",
    name: "resetpassword.index",
    component: ResetPassword,
    meta: {
      requiresAuth: true,
      module: modules.PROFILE,
      permission: permission.UPDATE
    }
  },
  {
    path: "/subscription",
    name: "subscription.index",
    component: Subscription,
    meta: {
      requiresAuth: true,
      module: modules.PROFILE,
      permission: permission.READ,
    }
  },
  {
    path: "/dashboard",
    name: "dashboard.index",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      module: modules.PROFILE,
      permission: permission.READ,
    }
  },
  {
    path: "/physician-log",
    name: "physicianRoom.index",
    component: PhysicianRoomList,
    meta: {
      requiresAuth: true,
      module: modules.PHYSICIANS,
      permission: permission.READ,
    }
  },
  {
    path: "/physician-log/add",
    name: "physicianRoom.create",
    component: PhysicianRoomForm,
    meta: {
      requiresAuth: true,
      module: modules.PHYSICIANS,
      permission: permission.CREATE,
    }
  },
  {
    path: "/physician-log/update/:id",
    name: "physicianRoom.update",
    component: PhysicianRoomForm,
    meta: {
      requiresAuth: true,
      module: modules.PHYSICIANS,
      permission: permission.UPDATE,
    }
  },
  {
    path: "/payment/:plan",
    name: "payment.index",
    meta: {
      requiresAuth: true,
      module: modules.PROFILE,
      permission: permission.READ,
    },
    component: PaymentForm
  },
  {
    path: "/user-management",
    component: EmptyRouterView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        redirect: {
          name: "user-management.list"
        },
      },
      {
        path: "",
        name: "user-management.list",
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "UserManagement" */ "@/views/userManagement/UserManagementList.vue"
          )
      },
      {
        path: "users",
        name: "users.index",
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "UserManagement" */ "@/views/user/UserList.vue"
          )
      },
      {
        path: "users/add",
        name: "user.create",
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "UserManagement" */ "@/views/user/UserForm.vue"
          )
      },
      {
        path: "/user/update/:id",
        name: "user.update",
        // component: UserForm,
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "UserManagement" */ "@/views/user/UserForm.vue"
          )
      },
      {
        path: "rep-users",
        name: "rep-users.index",
        // component: MedicalRepList,
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "UserManagement" */ "@/views/medicalRep/MedicalRepList.vue"
          )
      },
      {
        path: "/rep-user/update/:id",
        name: "rep-user.update",
        // component: MedicalRepForm,  
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "UserManagement" */ "@/views/medicalRep/MedicalRepForm.vue"
          )  
      },
      {
        path: "subscribers",
        name: "subscribers.index",
        component: SubscriberList,
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        }
      },
      {
        path: "subscriber/add",
        name: "subscriber.create",
        component: SubscriberForm,
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        }
      },
      {
        path: "/subscribers/update/:id",
        name: "subscribers.update",
        component: SubscriberForm,
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        }
      },
      {
        path: "physicians",
        name: "physicians.index",
        component: PhysicianList,
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        }
      },
      {
        path: "physicians/add",
        name: "physicians.create",
        component: PhysicianForm,
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        }
      },
      {
        path: "physicians/update/:id",
        name: "physicians.update",
        component: PhysicianForm,
        meta: {
          requiresAuth: true,
          module: modules.USER,
          permission: permission.CREATE,
        }
      }    
    ]
  },
  {
    path: "/meeting-approval",
    name: "meeting-approval.index",
    component: MeetingApprovalList,
    meta: {
      requiresAuth: true,
      module: modules.TENANTLOCATIONS,
      permission: permission.CREATE,
    }
  },
  {
    path: "/messages",
    name: "messages.index",
    component: Messages,
    meta: {
      requiresAuth: true,
      module: modules.MEETING,
      permission: permission.READ,
    }
  },
  {
    path: "/practice",
    name: "practice.index",
    component: PracticeList,
    meta: {
      requiresAuth: true,
      module: modules.TEAMS,
      permission: permission.CREATE,
    }
  },
  {
    path: "/locations",
    name: "locations.index",
    component: LocationList,
    meta: {
      requiresAuth: true,
      module: modules.TENANTLOCATIONS,
      permission: permission.CREATE,
    }
  },
  {
    path: "/locations/update/:id",
    name: "location.update",
    component: LocationUpdateForm,
    meta: {
      requiresAuth: true,
      module: modules.TENANTLOCATIONS,
      permission: permission.UPDATE,
    }
  },

  {
    path: "/restaurants",
    name: "restaurant.index",
    component: RestaurantList,
    meta: {
      requiresAuth: true,
      module: modules.RESTAURANTS,
      permission: permission.READ,
    }
  },
  {
    path: "/restaurants/add",
    name: "restaurant.create",
    component: RestaurantForm,
    meta: {
      requiresAuth: true,
      module: modules.RESTAURANTS,
      permission: permission.CREATE,
    },
  },
  {
    path: "/restaurants/update/:id",
    name: "restaurant.update",
    component: RestaurantForm,
    meta: {
      requiresAuth: true,
      module: modules.RESTAURANTS,
      permission: permission.UPDATE,
    }
  },
  {
    path: "/setting",
    component: EmptyRouterView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        redirect: {
          name: "setting.list"
        },
      },
      {
        path: "",
        name: "setting.list",
        meta: {
          requiresAuth: true,
          module: modules.POLICIES,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/views/setting/SettingList.vue"
          )
      },
      {
        path: "appointment-types",
        name: "appointment-types.index",
        meta: {
          requiresAuth: true,
          module: modules.APPOINTMENTTYPES,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/views/appointmentType/AppointmentTypeList.vue"
          )
      },
      {
        path: "preferences",
        name: "preferences.index",
        meta: {
          requiresAuth: true,
          module: modules.PREFERENCES,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/views/tenantPreference/TenantPreferenceList.vue"
          )
      },
      {
        path: "policies",
        name: "policies.index",
        meta: {
          requiresAuth: true,
          module: modules.POLICIES,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/views/tenantPolicy/TenantPolicyList.vue"
          )
      },
      {
        path: "appointment-rules",
        name: "appointment-rules.index",
        meta: {
          requiresAuth: true,
          module: modules.POLICIES,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/views/meetingRules/MeetingRulesList.vue"
          )
      },
      {
        path: "holidays",
        name: "holidays.index",
        meta: {
          requiresAuth: true,
          module: modules.POLICIES,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/views/holidays/HolidayList.vue"
          )
      },
      {
        path: "role-permissions",
        component: EmptyRouterView,
        meta: {
          requiresAuth: true,
          module: modules.POLICIES,
          permission: permission.CREATE,
        },
        children: [
          {
            path: "",
            redirect: {
              name: "role-permissions.index",
            },
          },
          {
            path: "",
            name: "role-permissions.index",
            meta: {
              requiresAuth: true,
              module: modules.POLICIES,
              permission: permission.CREATE,
            },
            component: () =>
              import(
                /* webpackChunkName: "Setting" */ "@/views/rolePermissions/RolePermissionList.vue"
              )
          },
          {
            path: "update/:id",
            meta: {
              requiresAuth: true,
              module: modules.APPOINTMENTTYPES,
              permission: permission.CREATE,
            },
            component: () =>
              import(
                /* webpackChunkName: "Setting" */ "@/views/rolePermissions/RolePermissionForm.vue"
              )
          },
        ]
      },
    ]

  },
  {
    path: "/teams",
    name: "teams.index",
    component: TeamList,
    meta: {
      requiresAuth: true,
      module: modules.TEAMS,
      permission: permission.CREATE,
    }
  },

  //  Meeting module
  {
    path: "/meeting",
    component: EmptyRouterView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        redirect: {
          name: "meeting.list"
        },
      },
      {
        path: "",
        name: "meeting.list",
        meta: {
          requiresAuth: true,
          module: modules.MEETING,
          permission: permission.READ,
        },
        component: () =>
          import(
            /* webpackChunkName: "Meeting" */ "@/views/meeting/MeetingList.vue"
          )
      },
      {
        path: "create",
        name: "meeting.create",
        meta: {
          requiresAuth: true,
          module: modules.MEETING,
          permission: permission.CREATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "Meeting" */ "@/views/meeting/MeetingForm.vue"
          )
      },
      {
        path: "update/:id",
        name: "meeting.update",
        meta: {
          requiresAuth: true,
          module: modules.MEETING,
          permission: permission.UPDATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "Meeting" */ "@/views/meeting/MeetingForm.vue"
          )
      },
      {
        path: "view/:id",
        name: "meeting.view",
        meta: {
          requiresAuth: true,
          module: modules.MEETING,
          permission: permission.READ,
        },
        component: () =>
          import(
            /* webpackChunkName: "Landing" */ "@/views/meeting/MeetingForm.vue"
          )
      },
      {
        path: ":id/view",
        name: "meeting.index",
        meta: {
          requiresAuth: true,
          module: modules.MEETING,
          permission: permission.READ,
        },
        component: () =>
          import(
            /* webpackChunkName: "Landing" */ "@/views/landing/Landing.vue"
          )
      },
    ]
  },

  {
    path: "/book-meeting",
    component: EmptyRouterView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        redirect: {
          name: "book-meeting.list"
        },
      },
      {
        path: "",
        name: "book-meeting.list",
        meta: {
          requiresAuth: true,
          module: modules.MEETING,
          permission: permission.READ,
        },
        component: () =>
          import(
            /* webpackChunkName: "Meeting" */ "@/views/meeting/BookMeetingList.vue"
          )
      },
      {
        path: "update/:id",
        name: "book-meeting.update",
        meta: {
          requiresAuth: true,
          module: modules.MEETING,
          permission: permission.UPDATE,
        },
        component: () =>
          import(
            /* webpackChunkName: "Meeting" */ "@/views/meeting/MeetingForm.vue"
          )
      },
    ]
  },  
  {
    path: "/past-meeting",
    component: EmptyRouterView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        redirect: {
          name: "past-meeting.list"
        },
      },
      {
        path: "",
        name: "past-meeting.list",
        meta: {
          requiresAuth: true,
          module: modules.MEETING,
          permission: permission.READ,
        },
        component: () =>
          import(
            /* webpackChunkName: "Meeting" */ "@/views/meeting/MeetingList.vue"
          )
      },      
    ]
  }, 
  {
    path: "/guestuser/meeting/:id/view",
    name: "landing.index",
    meta: {
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "Landing" */ "@/views/landing/Landing.vue"
      )
  },

  // Root page
  {
    path: "/",
    redirect: {
      name: "login.index"
    },
  },

  // Default page
  {
    path: "/*",
    redirect: {
      name: "login.index"
    },
  }
];

export default routes;
