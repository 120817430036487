/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Vue from "vue";
import axios from "axios";
import constants from "@/constants/app.properties";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";
import store from "@/store";

const getBaseUrl = () => {
  const {
    VUE_APP_API_PROTOCOL,
    VUE_APP_API_HOST,
    VUE_APP_BASE_URL
  } = process.env;

  if (window.heApiBaseURL && window.heApiBaseURL !== 'HE-API-RUNTIME') {
    // eslint-disable-next-line 
    console.log('heApiBaseURL:', window.heApiBaseURL);
    return window.heApiBaseURL;
  }

  // Default
  return `${VUE_APP_API_PROTOCOL}${VUE_APP_API_HOST}${VUE_APP_BASE_URL}`;
};

axios.defaults.baseURL = getBaseUrl();
axios.defaults.headers.common.Authorization = store.getters.isAuthenticated ? localStorage.getItem(constants.AUTH_TOKEN) : "";
axios.defaults.headers.common.Accept = "application/json";

//Refresh token changes
let invokeRefreshToken:any;
const getRefreshToken = (refreshToken:string) => {  
  return axios.post('/refresh-token', { refreshToken });
};
axios.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  error => {
    //Refresh token changes
    const aConfig = error.config;
    const oURLs = ['/login','/logout','/refresh-token'];
    if (!oURLs.includes(aConfig.url) && error.response) {
      if (error.response.data?.error?.message?.includes('jwt expired')) {
        const refreshToken: any = localStorage.getItem(constants.REFRESH_TOKEN);
        if (!invokeRefreshToken && refreshToken) {
          invokeRefreshToken = getRefreshToken(refreshToken).then(token => {
            invokeRefreshToken = null;
            return token;
          })
        }
        return invokeRefreshToken.then((res: any) => {
          store.dispatch("setToken", res.data);
          error.config.headers[
            'Authorization'
          ] = `Bearer ${res.data.accessToken}`;
          return axios(aConfig);
        }).catch((err: any) => {
          invokeRefreshToken = null;
          store.dispatch('logout');
          return Promise.reject(err);
        })
      }
    }

    if (error.response.status === 401) {      
      store.dispatch('logout');
    }

    if (error.response.data.error.statusCode === 401) {      
      localStorage.clear();
      store.dispatch('logout');
      if (error.response.data.error.message?.includes('jwt expired') || error.response.data.error.message?.includes('Entity not found:')){
        (error.response.data.error as any).message = msgConstants.SESSION_EXP;
      }
      
    }
    
    if (error.response.status === 500) {
      alert.methods.appToaster({ message: msgConstants.INTERNAL_SERVER_ERROR, type: "ERROR" });
    }
    return Promise.reject(error);
  }
);

// Bind axios to Vue.
Vue.prototype.$http = axios;
Vue.prototype.$baseUrl = getBaseUrl;

Object.defineProperties(Vue.prototype, {
  $baseUrl: {
    get() {
      return getBaseUrl();
    }
  },
  $http: {
    get() {
      return axios;
    }
  }
});

export default {
  getBaseUrl
};
